declare global {
    interface SizeOption extends Object {
        name: string|null,
        id: string,
        size: string,
        productId: string,
        position: number,
        productPhoto: string,
        whereToBuyLink: string,
        
    }
    interface ProductData extends Object {
        slug: string,
        type: string,
        name: string,
        swatchImage: string|null,
        showTints: boolean,
        sizeOptions: Record<string|number, SizeOption>
    }
    interface Window {
        productData: Record<string|number, Array<ProductData>>
        defaultProductImage: string;
        defaultBuyLink: string;
        selectedModel: string|null;
    }
}

// @ts-ignore
import Alpine from 'alpinejs'

import Component from '../base/Component';

export default class ProductDetailPage extends Component {
    init() {
        this.initStore()
    }

    initStore() {
        const productPageData = window.productData;
        const productPageDataTypes = Object.keys(window.productData);
        // This will be set to the pages' query parameter for "model"
        let selectedModel = window.selectedModel;

        Alpine.store('productpage', {
            $selectedItem: null,
            $selectedSize: null,
            $currentBuyLink: null,
            qty: 1,

            /**
             * Searches for product variants based on a given product ID
             *
             * This should probably be memoized
             * @param productId
             */
            getProductDetailsByProductId(productId: string) {
                let results = null

                productPageDataTypes.forEach(type => {
                    const productsByType = productPageData[type]
                    productsByType.forEach(product => {
                        const sizeOptions = product.sizeOptions;
                        const sizeOptionsKeys = Object.keys(sizeOptions);
                        sizeOptionsKeys.forEach(key => {
                            const size = sizeOptions[key];
                            if (size.productId && parseInt(productId) === parseInt(size.productId) || size.id && parseInt(productId) === parseInt(size.id)) {
                                results = {
                                    productType: type,
                                    product: product,
                                    size: size
                                }
                            }
                        });
                    })
                })
                return results
            },

            /**
             * Updates the current product and size
             * @param product
             * @param size
             * @param shouldPush
             */
            updateProductAndSize(product: ProductData|null, size: string|null, link: string|null, shouldPush = false) {
                if (product) {
                   this.$selectedItem = product
                }
                if (size) {
                    this.$selectedSize = size
                }
                if (link) {
                    this.$currentBuyLink = link
                }
                if (shouldPush) {
                    this.updateQueryParam()
                }
            },

            /**
             * Set the default product on page load (first item)
             */
            init() {
                if (productPageData && productPageDataTypes.length) {
                    let productDetails = null
                    if (selectedModel) {
                        productDetails = this.getProductDetailsByProductId(selectedModel)
                    }
                    if (productDetails) {
                        this.updateProductAndSize(productDetails.product, productDetails.size.size, false)
                    } else {
                        // Fallback to first item
                        this.updateProductAndSize(productPageData[productPageDataTypes[0]][0], null, false)
                    }
                } else {
                    console.error('Unable to determine a default product!')
                }
            },

            /**
             * Update the selected product
             * @param type The product type
             * @param slug The variant slug
             */
            updateSelectedItem(type: string, slug = null) {
                const productGroup = productPageData[type]
                if(!productGroup) {
                    console.error('Invalid product group selected', type)
                    return;
                }
                const product = productPageData[type].find((p: ProductData) => p.slug === slug)
                if (!product) {
                    console.error('Product does not exist in group', {slug, type})
                    return;
                }
                const sizeOptions = Object.keys(product.sizeOptions);
                let size = null
                if (sizeOptions.length) {
                    if (sizeOptions.includes(this.$selectedSize)) {
                        size = this.$selectedSize
                    } else {
                        size = product.sizeOptions[sizeOptions[0]].size;
                    }
                }

                this.updateProductAndSize(product, size, true)
                // this.selectedItem = product;
                console.debug('Selected Item Updated', this.selectedItem)
            },

            updateSelectedSize(size: string) {
                this.updateProductAndSize(null, size, true)
            },

            /**
             * Getter for the selected product size. Ensures the size selected
             * exists on the current product, if not select the first option
             */
            get selectedSize() {
                if (!this.selectedItem) {
                    return null;
                }
                // return this.$selectedSize;
                // TODO: Determine if this is still needed. Probably not.
                const sizeOptions = Object.keys(this.selectedItem.sizeOptions);
                if (sizeOptions.length) {
                    if (sizeOptions.includes(this.$selectedSize)) {
                        return this.$selectedSize
                    } else {
                        return this.selectedItem.sizeOptions[sizeOptions[0]].size;
                    }
                }
            },

            get selectedSizeObject() {
                if (!this.selectedSize) {
                    return null;
                }

                const sizeOptions = Object.keys(this.selectedItem.sizeOptions);
                const indexOfSize = sizeOptions.indexOf(this.selectedSize)
                return this.selectedItem.sizeOptions[indexOfSize]
            },

            set selectedSize(value) {
                this.$selectedSize = value;
            },

            get selectedItem() {
                return this.$selectedItem
            },

            set selectedItem(value) {
                this.$selectedItem = value;
            },
            /**
             * Get the active variant data based on the selected options
             */
            get currentBuyLink() {
                if (this.selectedItem) {
                    const selectedSize = this.selectedItem.sizeOptions[this.selectedSize];
                    console.log(this.selectedItem)
                    if (selectedSize && selectedSize.whereToBuyLink) {
                        return selectedSize.whereToBuyLink
                    }
                }
                return window.defaultBuyLink;
            },

            /**
             * Get the current product image based on selected options.
             * Allows for specific sizes to have their own images or to fallback to the product default
             *
             */
            get currentProductImage() {
                if (this.selectedItem) {
                    const selectedSize = this.selectedItem.sizeOptions[this.selectedSize];
                    if (selectedSize && selectedSize.productPhoto) {
                        return selectedSize.productPhoto
                    }
                }
                return window.defaultProductImage;
            },
            updateQueryParam() {
                const selectedSize = this.selectedSize;
                const selectedSizeObject = this.selectedItem.sizeOptions[selectedSize];

                const productId = selectedSizeObject.productId ?? selectedSizeObject.id;
                if ('URLSearchParams' in window) {
                    var searchParams = new URLSearchParams(window.location.search)
                    searchParams.set("model", productId);
                    var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                    history.pushState({
                        productId: productId
                    }, '', newRelativePathQuery);
                }
                if (selectedSizeObject.productId) {
                    document.querySelectorAll('[data-bv-product-id]').forEach(function ($el) {
                        $el.dataset.bvProductId = selectedSizeObject.productId;
                        console.log('Updated product id on bv item', $el)
                    });
                }
            }
        })

        window.addEventListener('popstate', (e) => {
            if (! e.state) return
            if (! e.state.productId) return

            let selectedModel = e.state.productId
            let productDetails = null
            if (selectedModel) {
                productDetails = Alpine.store('productpage').getProductDetailsByProductId(selectedModel)
            }
            console.log(productDetails)

            if (productDetails) {
                Alpine.store('productpage').updateProductAndSize(productDetails.product, productDetails.size.size, false)
            }
        })

    }

}